@import "@/assets/style/_index.scss";



















































.lottery-turntable2-page {
    img {
        display: block;
        width: 100%;
        height: auto;
    }
}
