@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.goods-swiper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
.goods-swiper > .swiper-container {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.goods-swiper > .sold-out {
  position: absolute;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.5) url(../../static/img/icon01.da5f90f1.png) center no-repeat;
  background-size: 70%;
  width: 100%;
  height: 100%;
}
.goods-info {
  background-color: #fff;
}
.goods-info > .header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
}
.goods-info > .header > .label,
.goods-info > .header > .price-wrap,
.goods-info > .header > .countdown-wrap {
  min-height: 1rem;
  padding: 0 0.2rem;
}
.goods-info > .header > .label {
  -webkit-box-flex: 0.54;
  -webkit-flex: 0.54 0.54 1%;
          flex: 0.54 0.54 1%;
}
.goods-info > .header > .price-wrap {
  -webkit-box-flex: 0.54;
  -webkit-flex: 0.54 0.54 1%;
          flex: 0.54 0.54 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: #e52779;
  background-color: var(--color-main, #e52779);
}
.goods-info > .header > .price-wrap .price {
  color: #fff;
  line-height: 1.2;
}
.goods-info > .header > .price-wrap > .price {
  font-size: 0.4rem;
}
.goods-info > .header > .price-wrap > .old-price {
  color: #fff;
  font-size: 0.22rem;
  line-height: 1.2;
}
.goods-info > .header > .countdown-wrap {
  -webkit-box-flex: 0.46;
  -webkit-flex: 0.46 0.46 1%;
          flex: 0.46 0.46 1%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #ffcc33;
}
.goods-info > .header > .countdown-wrap > .countdown {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #f00;
}
.goods-info > .body {
  padding: 0.3rem 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.goods-info > .body > .label-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
.goods-info > .body > .label-wrap > .name {
  font-size: 0.32rem;
}
.goods-info > .body > .label-wrap > .desc {
  color: #868e96;
  line-height: 1.2;
  margin-top: 0.2rem;
}
.goods-info > .body > .share {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.22rem;
}
.goods-info > .body > .share > .f-icon {
  font-size: 0.6rem;
  line-height: 1;
  margin-bottom: 0.1rem;
}
.goods-info > .body > .share.active {
  color: #f00;
}
.goods-info > .footer {
  padding: 0.2rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.turntable-title {
  padding-top: 0.5rem;
  position: relative;
  z-index: 101;
}
.turntable-title > .badge-1194 {
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  width: 1.6rem;
}
.turntable-title > .title {
  width: 7.36rem;
  margin: 0 auto;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.turntable {
  position: relative;
  width: 6.38rem;
  height: 6.38rem;
  margin: 0 auto;
}
.turntable > .bg,
.turntable > .front {
  pointer-events: none;
}
.turntable > .bg,
.turntable > .front,
.turntable > .inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.turntable > .inner {
  border-radius: 100vw;
}
.turntable > .inner > .item {
  position: absolute;
  left: 50%;
  height: 50%;
  padding-top: 1rem;
  -webkit-transform-origin: 50% 3.19rem;
          transform-origin: 50% 3.19rem;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.turntable > .inner > .item > .inner > .gift {
  width: 1rem;
  height: 1rem;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.turntable > .inner > .item:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 0.02rem;
  height: 100%;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #320f00;
}
.turntable > .inner > .item:nth-child(1) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 0));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 0));
}
.turntable > .inner > .item:nth-child(1) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 0));
          transform: rotate(calc(-360deg/var(--gift-length) * 0));
}
.turntable > .inner > .item:nth-child(1):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(2) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 1));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 1));
}
.turntable > .inner > .item:nth-child(2) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 1));
          transform: rotate(calc(-360deg/var(--gift-length) * 1));
}
.turntable > .inner > .item:nth-child(2):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(3) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 2));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 2));
}
.turntable > .inner > .item:nth-child(3) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 2));
          transform: rotate(calc(-360deg/var(--gift-length) * 2));
}
.turntable > .inner > .item:nth-child(3):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(4) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 3));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 3));
}
.turntable > .inner > .item:nth-child(4) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 3));
          transform: rotate(calc(-360deg/var(--gift-length) * 3));
}
.turntable > .inner > .item:nth-child(4):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(5) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 4));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 4));
}
.turntable > .inner > .item:nth-child(5) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 4));
          transform: rotate(calc(-360deg/var(--gift-length) * 4));
}
.turntable > .inner > .item:nth-child(5):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(6) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 5));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 5));
}
.turntable > .inner > .item:nth-child(6) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 5));
          transform: rotate(calc(-360deg/var(--gift-length) * 5));
}
.turntable > .inner > .item:nth-child(6):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(7) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 6));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 6));
}
.turntable > .inner > .item:nth-child(7) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 6));
          transform: rotate(calc(-360deg/var(--gift-length) * 6));
}
.turntable > .inner > .item:nth-child(7):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(8) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 7));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 7));
}
.turntable > .inner > .item:nth-child(8) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 7));
          transform: rotate(calc(-360deg/var(--gift-length) * 7));
}
.turntable > .inner > .item:nth-child(8):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(9) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 8));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 8));
}
.turntable > .inner > .item:nth-child(9) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 8));
          transform: rotate(calc(-360deg/var(--gift-length) * 8));
}
.turntable > .inner > .item:nth-child(9):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(10) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 9));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 9));
}
.turntable > .inner > .item:nth-child(10) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 9));
          transform: rotate(calc(-360deg/var(--gift-length) * 9));
}
.turntable > .inner > .item:nth-child(10):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(11) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 10));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 10));
}
.turntable > .inner > .item:nth-child(11) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 10));
          transform: rotate(calc(-360deg/var(--gift-length) * 10));
}
.turntable > .inner > .item:nth-child(11):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(12) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 11));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 11));
}
.turntable > .inner > .item:nth-child(12) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 11));
          transform: rotate(calc(-360deg/var(--gift-length) * 11));
}
.turntable > .inner > .item:nth-child(12):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(13) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 12));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 12));
}
.turntable > .inner > .item:nth-child(13) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 12));
          transform: rotate(calc(-360deg/var(--gift-length) * 12));
}
.turntable > .inner > .item:nth-child(13):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(14) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 13));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 13));
}
.turntable > .inner > .item:nth-child(14) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 13));
          transform: rotate(calc(-360deg/var(--gift-length) * 13));
}
.turntable > .inner > .item:nth-child(14):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(15) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 14));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 14));
}
.turntable > .inner > .item:nth-child(15) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 14));
          transform: rotate(calc(-360deg/var(--gift-length) * 14));
}
.turntable > .inner > .item:nth-child(15):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .item:nth-child(16) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 15));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 15));
}
.turntable > .inner > .item:nth-child(16) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 15));
          transform: rotate(calc(-360deg/var(--gift-length) * 15));
}
.turntable > .inner > .item:nth-child(16):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .inner > .start-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.08rem;
  height: 1.08rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.turntable > .inner > .start-btn > img {
  position: relative;
  z-index: 100;
}
.turntable > .inner > .start-btn > .pointer {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 0.48rem;
  height: 0.94rem;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.lottery-turntable-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  background: transparent url(../../static/img/bg.92506f0d.jpg) top center/cover no-repeat;
  color: #fff;
}
.lottery-turntable-page img {
  display: block;
  width: 100%;
  height: auto;
}
.lottery-turntable-page > .rule-btn {
  display: block;
  position: fixed;
  z-index: 110;
  right: 0.3rem;
  top: 0.3rem;
  width: 1.33rem;
}
.lottery-turntable-page > .gift-list-btn {
  display: block;
  position: fixed;
  z-index: 110;
  right: 0.3rem;
  top: 0.9rem;
  width: 1.33rem;
}
.lottery-turntable-page > .turntable {
  margin-top: -0.8rem;
}
.lottery-turntable-page > .result-warp {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
  margin-top: -0.5rem;
}
.lottery-turntable-page > .result-warp > .inner {
  position: relative;
  width: 5.45rem;
  height: 100%;
  margin: 0 auto;
  background-color: rgba(107, 50, 25, 0.78);
  border: solid #e49875;
  border-width: 0.267vw 0.267vw 0 0.267vw;
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
}
.lottery-turntable-page > .result-warp > .inner > .gift {
  position: absolute;
  top: -1.3rem;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 7.5rem;
  overflow: hidden;
}
.lottery-turntable-page > .water {
  position: absolute;
  z-index: 110;
}
.lottery-turntable-page > .water.left {
  left: 0;
  bottom: 0;
  width: 3.39rem;
}
.lottery-turntable-page > .water.right {
  right: 0;
  bottom: 2.55rem;
  width: 1.55rem;
}
.lottery-turntable-page > .lottery-badge {
  position: absolute;
  z-index: 110;
  right: 0.3rem;
  bottom: 0.3rem;
  width: 1.92rem;
}
.save-delivery-dialog > .ctrl {
  text-align: center;
}
.save-delivery-dialog > .tips {
  font-size: 85%;
  color: #999;
  margin-bottom: 0.2rem;
}
.save-delivery-dialog > .save-info > .item {
  line-height: 1.2;
  padding: 0.2rem 0;
}
.save-delivery-dialog > .save-info > .item > .label {
  font-size: 85%;
  color: #999;
}
.save-delivery-dialog > .save-info > .item + .item {
  border-top: 1px solid #f1f1f1;
}
.follow-qrcode > .header {
  margin-bottom: 0.2rem;
  text-align: center;
  background-color: #f00;
  color: #fff;
  font-size: 5.333vw;
  font-weight: bold;
  letter-spacing: 0.5em;
  border-radius: 0.1rem;
  padding: 0.1rem 0;
}
.follow-qrcode > .footer {
  text-align: center;
  margin-top: 0.2rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.lottery-turntable-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  background: transparent url(../../static/img/bg.92506f0d.jpg) top center/cover no-repeat;
  color: #fff;
}
.lottery-turntable-page img {
  display: block;
  width: 100%;
  height: auto;
}
.lottery-turntable-page > .rule-btn {
  display: block;
  position: fixed;
  z-index: 110;
  right: 0.3rem;
  top: 0.3rem;
  width: 1.33rem;
}
.lottery-turntable-page > .turntable {
  margin-top: -0.8rem;
}
.lottery-turntable-page > .rule-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-top: -0.5rem;
}
.lottery-turntable-page > .rule-wrap > .inner {
  position: relative;
  width: 5.44rem;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  background-color: rgba(107, 50, 25, 0.78);
  border: solid #e49875;
  border-width: 0.267vw 0.267vw 0 0.267vw;
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  padding: 0.3rem;
}
.lottery-turntable-page > .water {
  position: fixed;
  z-index: 110;
}
.lottery-turntable-page > .water.left {
  left: 0;
  bottom: 0;
  width: 3.39rem;
}
.lottery-turntable-page > .water.right {
  right: 0;
  bottom: 2.55rem;
  width: 1.55rem;
}
.lottery-turntable-page > .lottery-badge {
  position: fixed;
  z-index: 110;
  right: 0.3rem;
  bottom: 0.3rem;
  width: 1.92rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.gift-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 0.2rem;
  background-color: #fff;
}
.gift-item > .label-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 1%;
          flex: 1 1 1%;
}
.gift-item > .label-wrap > .label {
  line-height: 1.2;
}
.gift-item > .label-wrap > .express {
  line-height: 1.2;
  color: #999;
  margin-top: 0.1rem;
}
.gift-item > .label-wrap > .express > .item > span {
  color: #333333;
}
.gift-item > .label-wrap > .time {
  font-size: 85%;
  color: #999;
  margin-top: 0.1rem;
}
.gift-item > .extra {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  margin-left: 0.1rem;
}
.gift-item + .gift-item {
  border-top: 1px solid #f1f1f1;
}
.save-delivery-dialog > .ctrl {
  text-align: center;
}
.save-delivery-dialog > .tips {
  font-size: 85%;
  color: #999;
  margin-bottom: 0.2rem;
}
.save-delivery-dialog > .save-info > .item {
  line-height: 1.2;
  padding: 0.2rem 0;
}
.save-delivery-dialog > .save-info > .item > .label {
  font-size: 85%;
  color: #999;
}
.save-delivery-dialog > .save-info > .item + .item {
  border-top: 1px solid #f1f1f1;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.turntable {
  width: 88%;
  margin: 0 auto;
}
.turntable > .content {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}
.turntable > .content > .inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.turntable > .content > .inner > .bg,
.turntable > .content > .inner > .front {
  pointer-events: none;
}
.turntable > .content > .inner > .bg,
.turntable > .content > .inner > .front,
.turntable > .content > .inner > .inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.turntable > .content > .inner > .inner {
  border-radius: 100vw;
}
.turntable > .content > .inner > .inner > .item {
  position: absolute;
  left: 50%;
  height: 50%;
  padding-top: 16%;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.turntable > .content > .inner > .inner > .item > .inner > .gift {
  width: 1.1rem;
  height: 1.1rem;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.turntable > .content > .inner > .inner > .item:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 0.02rem;
  height: 100%;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #320f00;
}
.turntable > .content > .inner > .inner > .item:nth-child(1) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 0));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 0));
}
.turntable > .content > .inner > .inner > .item:nth-child(1) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 0));
          transform: rotate(calc(-360deg/var(--gift-length) * 0));
}
.turntable > .content > .inner > .inner > .item:nth-child(1):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(2) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 1));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 1));
}
.turntable > .content > .inner > .inner > .item:nth-child(2) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 1));
          transform: rotate(calc(-360deg/var(--gift-length) * 1));
}
.turntable > .content > .inner > .inner > .item:nth-child(2):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(3) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 2));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(3) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 2));
          transform: rotate(calc(-360deg/var(--gift-length) * 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(3):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(4) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 3));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 3));
}
.turntable > .content > .inner > .inner > .item:nth-child(4) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 3));
          transform: rotate(calc(-360deg/var(--gift-length) * 3));
}
.turntable > .content > .inner > .inner > .item:nth-child(4):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(5) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 4));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 4));
}
.turntable > .content > .inner > .inner > .item:nth-child(5) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 4));
          transform: rotate(calc(-360deg/var(--gift-length) * 4));
}
.turntable > .content > .inner > .inner > .item:nth-child(5):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(6) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 5));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 5));
}
.turntable > .content > .inner > .inner > .item:nth-child(6) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 5));
          transform: rotate(calc(-360deg/var(--gift-length) * 5));
}
.turntable > .content > .inner > .inner > .item:nth-child(6):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(7) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 6));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 6));
}
.turntable > .content > .inner > .inner > .item:nth-child(7) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 6));
          transform: rotate(calc(-360deg/var(--gift-length) * 6));
}
.turntable > .content > .inner > .inner > .item:nth-child(7):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(8) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 7));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 7));
}
.turntable > .content > .inner > .inner > .item:nth-child(8) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 7));
          transform: rotate(calc(-360deg/var(--gift-length) * 7));
}
.turntable > .content > .inner > .inner > .item:nth-child(8):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(9) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 8));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 8));
}
.turntable > .content > .inner > .inner > .item:nth-child(9) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 8));
          transform: rotate(calc(-360deg/var(--gift-length) * 8));
}
.turntable > .content > .inner > .inner > .item:nth-child(9):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(10) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 9));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 9));
}
.turntable > .content > .inner > .inner > .item:nth-child(10) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 9));
          transform: rotate(calc(-360deg/var(--gift-length) * 9));
}
.turntable > .content > .inner > .inner > .item:nth-child(10):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(11) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 10));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 10));
}
.turntable > .content > .inner > .inner > .item:nth-child(11) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 10));
          transform: rotate(calc(-360deg/var(--gift-length) * 10));
}
.turntable > .content > .inner > .inner > .item:nth-child(11):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(12) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 11));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 11));
}
.turntable > .content > .inner > .inner > .item:nth-child(12) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 11));
          transform: rotate(calc(-360deg/var(--gift-length) * 11));
}
.turntable > .content > .inner > .inner > .item:nth-child(12):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(13) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 12));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 12));
}
.turntable > .content > .inner > .inner > .item:nth-child(13) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 12));
          transform: rotate(calc(-360deg/var(--gift-length) * 12));
}
.turntable > .content > .inner > .inner > .item:nth-child(13):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(14) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 13));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 13));
}
.turntable > .content > .inner > .inner > .item:nth-child(14) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 13));
          transform: rotate(calc(-360deg/var(--gift-length) * 13));
}
.turntable > .content > .inner > .inner > .item:nth-child(14):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(15) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 14));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 14));
}
.turntable > .content > .inner > .inner > .item:nth-child(15) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 14));
          transform: rotate(calc(-360deg/var(--gift-length) * 14));
}
.turntable > .content > .inner > .inner > .item:nth-child(15):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .item:nth-child(16) {
  -webkit-transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 15));
          transform: translateX(-50%) rotate(calc(360deg/var(--gift-length) * 15));
}
.turntable > .content > .inner > .inner > .item:nth-child(16) > .inner > .gift {
  -webkit-transform: rotate(calc(-360deg/var(--gift-length) * 15));
          transform: rotate(calc(-360deg/var(--gift-length) * 15));
}
.turntable > .content > .inner > .inner > .item:nth-child(16):before {
  -webkit-transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
          transform: translateX(-50%) rotate(calc(-360deg/var(--gift-length) / 2));
}
.turntable > .content > .inner > .inner > .start-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22.7272727273%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.turntable > .content > .inner > .inner > .start-btn > .inner {
  padding-top: 100%;
}
.turntable > .content > .inner > .inner > .start-btn > .inner > img {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.turntable > .content > .inner > .inner > .start-btn > .pointer {
  position: absolute;
  bottom: 50%;
  left: 50%;
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  max-width: 100%;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.lottery-turntable2-page {
  /*display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background: transparent url('./images/bg.jpg') top center / 100% auto no-repeat;*/
}
.lottery-turntable2-page img {
  display: block;
  width: 100%;
  height: auto;
}
.lottery-turntable2-page > .content {
  position: relative;
  color: #fff;
}
.lottery-turntable2-page > .content > .rule-btn {
  display: block;
  position: absolute;
  z-index: 110;
  right: 0.26rem;
  top: 0.4rem;
  width: 1.33rem;
}
.lottery-turntable2-page > .content > .gift-list-btn {
  display: block;
  position: absolute;
  z-index: 110;
  right: 0.26rem;
  top: 0.9rem;
  width: 1.33rem;
}
.lottery-turntable2-page > .content > .bg {
  height: 100vh;
  overflow: hidden;
}
.lottery-turntable2-page > .content > .inner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
          align-items: stretch;
  background: transparent url(../../static/img/bg.be98d14b.jpg) top center/100% auto no-repeat;
}
.lottery-turntable2-page > .content > .inner > .turntable {
  margin-top: 72%;
}
.lottery-turntable2-page > .content > .inner > .result-warp {
  z-index: 100;
}
.lottery-turntable2-page > .content > .inner > .footer-bg {
  margin-top: -20vw;
  /*position: absolute;
  left: 0;
  right: 0;
  top: (1046px);*/
}
.save-delivery-dialog > .ctrl {
  text-align: center;
}
.save-delivery-dialog > .tips {
  font-size: 85%;
  color: #999;
  margin-bottom: 0.2rem;
}
.save-delivery-dialog > .save-info > .item {
  line-height: 1.2;
  padding: 0.2rem 0;
}
.save-delivery-dialog > .save-info > .item > .label {
  font-size: 85%;
  color: #999;
}
.save-delivery-dialog > .save-info > .item + .item {
  border-top: 1px solid #f1f1f1;
}
.follow-qrcode > .header {
  margin-bottom: 0.2rem;
  text-align: center;
  background-color: #f00;
  color: #fff;
  font-size: 5.333vw;
  font-weight: bold;
  letter-spacing: 0.5em;
  border-radius: 0.1rem;
  padding: 0.1rem 0;
}
.follow-qrcode > .footer {
  text-align: center;
  margin-top: 0.2rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.lottery-turntable2-page img {
  display: block;
  width: 100%;
  height: auto;
}

/*# sourceMappingURL=preview.a65b7b09.css.map*/